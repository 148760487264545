<template>
  <div class="top no-subnav" ref="welcome"> 
    <vueVimeoPlayer
        height="100%"
        width="100%"            
        class='video'
        frameborder="0"
        :controls=false         
        :options="{
            autoplay:true,
            muted:true,
            loop:true
        }"        
        video-id="572120370"
        :style="videoStyle"
        :eventsToEmit=[]
    />  
    <transition name="slide-fade" mode="out-in" appear>
      <div class='limit-width'>
        <p class="caps accent">Welcome to Enmarket Arena</p>
        <h1 class="white">THE UNRIVALED NEW SPORTS & ENTERTAINMENT VENUE of the southeast.</h1>
        <h4 class="white">A Timeless Venue in One of the Nation’s Most Historic Cities</h4>
        <div class='cta'>
          <Button text='Sign up' secondary @click="$store.state.showSignup = true; $store.state.blockScroll = true;"/>
        </div>
      </div> 
      <!--<Button text='Skip Video' secondary class='skip-video'/>-->
    </transition>
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import Button from '@/components/Button';
export default {
  name: 'Welcome',
  components: {
    Button,
    vueVimeoPlayer
  },
  data: () => ({ videoStyle: {} }),   
  mounted() {
      this.adjustVideo();
      window.addEventListener("resize", this.adjustVideo);      
  },
  beforeDestroy: function () {
      window.removeEventListener("resize", this.adjustVideo);
  },
  methods: {
    adjustVideo() {
      let height = this.$refs.welcome.clientHeight;
      let width = window.innerWidth;
      let vw, vh;
      if (width / height > 16 / 9) {
          vw = Math.round(width);
          vh = Math.round((vw * 9) / 16);
      } else {
          vh = Math.round(height);
          vw = Math.round((vh * 16) / 9);
      }
      this.videoStyle = {
          width: vw + "px",
          height: vh + "px",
          left: (width - vw) / 2 + "px",
          right: (height - vh) / 2 + "px",
      };
      },
  },
}
</script>
<style lang="scss">
    .video iframe  {
        width:100%;
        height:100%;
    }
</style>
<style lang="scss" scoped>
  .video {
      position:absolute;
      top:0;
      left:0;
      width:100vw;
      height:100%;        
  }
  h1 {
    max-width: 650px;
    margin-top:22px;
    margin-bottom:12px;
  }  
  h4 {
    max-width: 350px;
  }
  .top {   
    position: relative; 
    overflow: hidden;
    &:before {
      z-index: 1;
    }
    .limit-width {
      position: relative;
      z-index:1;
    }
    .cta {
      margin-top: 20px;
    }
  }
  .skip-video {
    position:absolute;
    bottom:65px;
    right:100px;
  }
</style>
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';  
import Welcome from '../views/Welcome.vue'

const routes = [
  {
    path: '/:purl?',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/:purl?/oak-view-group',
    name: 'Oak View Group',
    component: () => import(/* webpackChunkName: "oak-view-group" */ '../views/OakViewGroup.vue'), props: true 
  },
  {
    path: '/:purl?/canal-district',
    name: 'Canal District',
    component: () => import(/* webpackChunkName: "canal-district" */ '../views/CanalDistrict.vue'), props: true 
  },
  {
    path: '/:purl?/enmarket-arena/',
    name: 'Enmarket Arena',
    component: () => import(/* webpackChunkName: "enmarket-arena" */ '../views/EnmarketArena.vue'), props: true 
  },
  {
    path: '/:purl?/suites',
    name: 'Suites',
    component: () => import(/* webpackChunkName: "suites" */ '../views/Suites.vue'), props: true 
  },
  {
    path: '/:purl?/north-club',
    name: 'North Club',
    component: () => import(/* webpackChunkName: "north-club" */ '../views/NorthClub.vue'), props: true 
  },
  {
    path: '/:purl?/summary',
    name: 'Summary',
    component: () => import(/* webpackChunkName: "summary" */ '../views/Summary.vue'), props: true 
  } 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      store.state.linkScrolling = true;
      const el = window.location.href.split("#")[1];
      if (el.length) {
        let timeout = 1;
        if(document.getElementById(el) === null) {
          timeout = 500
        }
        //now that components are added dynamically based on CMS; html elements don't exist until after about 500ms
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            document.getElementById(el).scrollIntoView({ behavior: "smooth", block: "end" });
            store.state.linkScrolling = false;
          }, timeout)
        });        
      }
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  },
})

export default router

<template>
  <div id="app">
    <Nav />    
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" class='current-view' />
      </transition>
    </router-view>  
    <Modal v-show="$store.state.showSignup" @close="$store.state.showSignup = $store.state.blockScroll = false;"> 
      <template v-slot:content>
          <SignUp />
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios';
import Nav from '@/components/Nav';
import Modal from '@/components/Modal';
import SignUp from '@/pages/SignUp';
export default {
  name: 'Enmarket',
  components: {
    Nav,
    Modal,
    SignUp
  },
  computed: {
    blockScroll () {
      return this.$store.state.blockScroll;      
    }
  },
  created() {
      if(window.location.href.slice(-1) !== '/') {
        window.location.href = `${window.location.href}/`;        
      }
      setTimeout(() => {
        this.getPurl();  
      }, 100);      
  },  
  methods : {
    getPurl() {
      let purl = this.$route.params.purl;
      if(purl?.indexOf('preview.') == 0) {
          const previewPurl = purl.split('preview.');
          purl = previewPurl.pop();
          this.$store.commit('setPreview', true);        
      } else {
        this.$store.commit('setPurl', purl);        
      }
      if(purl === 'undefined') {
        return;
      }
      axios.get(this.$store.state.baseUrl + "SiteEndpoint.php?purl=" + purl)
      .then(this.getSite)
      .catch(function (error) {
          console.log(error);
      }); 
    },
    getSite(response) {      
      let client = {};
      let rep = {};
      
      client.clientName = decodeURIComponent(response.data.site.client.name);
      client.clientEmail = decodeURIComponent(response.data.site.client.email);
      const repName = decodeURIComponent(response.data.site.rep.first);
      const repNameParts = repName.split(" ");
      rep.repLast = repNameParts.pop();
      rep.repFirst = repNameParts.join(" ")
      
      rep.repPosition = decodeURIComponent(response.data.site.rep.position);
      rep.repPhone = decodeURIComponent(response.data.site.rep.phone);
      rep.repEmail = decodeURIComponent(response.data.site.rep.email);
      
      response.data.site.pages.forEach(element => {
          if(element.id == "10") {
            this.$store.commit('setSummary', element);              
          }
      });   
       this.$store.commit('setPages', response.data.site.pages);
       this.$store.commit('setClient', client);
       this.$store.commit('setRep', rep);

       this.addVisit();
    },
    trackVisit(response) {
      if(response.data.status == 'ok') {
        this.$store.commit('setSessionDBid', response.data.sessionDbId);
      }
    },
    addVisit() {
      if(this.$store.state.preview) {
        return;
      }
      const post = {
          method:"visit",
          purl:this.$store.state.purl,
      }
      axios.post(this.$store.state.baseUrl + "EpitchLiteTracking.php", post)
      .then(this.trackVisit)
      .catch(function (error) {
          console.log(error);
      })
    },
  },
  watch: {
    blockScroll (value) {      
      const body = document.body;
      value ? body.classList.add('is-scrolling-blocked') : body.classList.remove('is-scrolling-blocked');
    }
  },
  data () {
    return {
    }
  },
}
</script>

<style lang="scss">
@font-face {
      font-family: 'Proxima Nova';
      src: url('~@/assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
          url('~@/assets/fonts/ProximaNova-Regular.woff') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }
  @font-face {
    font-family: 'Proxima Nova';
    src: url('~@/assets/fonts/ProximaNova-Semibold.woff2') format('woff2'),
        url('~@/assets/fonts/ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('~@/assets/fonts/ProximaNova-Black.woff2') format('woff2'),
        url('~@/assets/fonts/ProximaNova-Black.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
.limit-width {
  width:100%;
  max-width: 1300px;
  margin:0 auto;
  position: relative;
}
.is-scrolling-blocked #app   {
    overflow:hidden;
    height:100vh;
  }
html {
  background: #484848;  
}
body, p, h1, h2, h3, h4, em {  
  margin:0;  
}
body {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #202020;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #E43141;
  }
}
#app {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.4;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.absolute {
  position:absolute;
  top:0;
  left:0;
}
.body {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.4;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.caps {
  font-family: 'Proxima Nova';  
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: 0.03rem;
  font-variant: small-caps; 
  color:inherit;
}
em {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: bold;
  font-size: 5rem;
  line-height: 0.9;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  color:inherit;
}
h1 {
  max-width: 650px;
  margin-top:22px;
  margin-bottom:12px;
}  
h4 {
  max-width: 350px;
} 
.main > div {
  position: relative;
} 
.one-line {
  display: flex;
  align-items: center;
  h1 {
    margin-left:15px;
  }
} 
h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  color:inherit;
}
h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 1;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  color:inherit;
}
h3 {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  color:inherit;
}
h4 {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.3;
  letter-spacing: 0.02rem;
  color:inherit;
}
ul {
  margin-left:1rem;
}
.button {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 100%;
  letter-spacing: 0.75px;
  text-transform: uppercase;
}
.centered-headline {
  position:relative;
  overflow:auto;
  min-height:calc(100vh - 140px);
  overflow: auto;
  text-align:center;
  z-index: 1;
  > div {
    margin:0 auto;
    margin-top:calc(4.5em + 58px);
    display:block;
    max-width: 650px;
    h4 {
      max-width: 430px;
      margin:0 auto;
    }
  }
}
.accent {
  color:#E43141;
}
.white {
  color:#FFF;
}
.gray {
  color:#484848;
}
.dark {
  color:#202020;
}
.light-gradient {
  position: relative;
  &:before {
      background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      content:"";
  }
}
.dark-gradient {
  position: relative;
  &:before {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.86) 0%, rgba(54, 65, 51, 0) 100%);
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      content:"";
  }
}
.fade-enter-active {
  transition: opacity 0.25s ease;
}
.fade-leave-active {
  transition: opacity 0.25s cubic-bezier(1, 0.5, 0.8, 1);
  
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 0.25s ease;
}
.slide-fade-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-25px);
  opacity: 0;
}
.add-padding {
  padding:0 2.25rem;         
}
.top {
  min-height:calc(100vh - 140px);
  display:flex;
  align-items: center;
  padding:0 2.25rem;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  &.no-subnav {
    min-height:calc(100vh - 110px);
  }
  &:before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.86) 0%, rgba(54, 65, 51, 0) 100%);
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    content:"";
  }
  > div {
    position: relative;
  }
}
.scroll-small-window {
  display:flex;
  align-items: center;  
  height:inherit;
  overflow: auto;    
}
@media (min-width:500px) {
  .add-padding {
    padding:0 6.25rem;         
  }
  .top {
    padding:0 6.25rem;
  }
}
</style>

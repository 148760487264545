<template>
  <div id="nav" class='add-padding'>
      <div class='limit-width' :class="[mobileNavNeeded ? 'has-mobile-nav' : '']">
        <div class="logo"><img src="@/assets/enmarket.svg" alt=""></div>
        <div v-if="mobileNavNeeded" class="mobile-menu-toggle" @click="mobileNavOpen = true; $store.state.blockScroll = true;"></div>
        <div v-if="!mobileNavNeeded" class="nav-items" ref="desktopNav">
            <router-link class='caps' :class="[nav.path === '/' ? 'first' : '']" v-for="nav in activeNavItems" :to="{name: nav.name, params:$route.params}" :key="nav.name">{{nav.name}}</router-link>
        </div>
     </div>
     <div class='mobile-menu' v-if="mobileNavNeeded" :class="[mobileNavOpen ? 'open' : '']" @click="mobileNavOpen = false; $store.state.blockScroll = false;">
        <div>             
            <div class="menu-top">
                <div class="logo"><img src="@/assets/enmarket.svg" alt=""></div>       
            </div>
            <div class="nav-items">
                <div  v-for="(nav, id) in activeNavItems" :key="id" class="nav-section">
                    <router-link class='caps' :class="[nav.path === '/' ? 'first' : '']"  :to="{name: nav.name, params:$route.params}" :key="nav.name">{{nav.name}}</router-link>                                          
                </div>
            </div>            
        </div>            
    </div>        
  </div>
</template>

<script>
export default {
    name:'Nav',
    data: () => ({     
        mobileNavOpen:false,
        mobileNavNeeded:false,
        widthToRevertAt:undefined,    
        navItems:[
            {
                path:'/',
                name:'Welcome',
            },
            {
                path:'/oak-view-group',
                name:'Oak View Group',
                id:'1'
            },
            {
                path:'/canal-district',
                name:'Canal District',
                id:'2'
            },
            {
                path:'/enmarket-arena',
                name:'Enmarket Arena',
                id:'3'
            },
            {
                path:'/suites',
                name:'Suites',
                id:'4'
            },
            {
                path:'/north-club',
                name:'North Club',
                id:'5'
            },
            {
                path:'/summary',
                name:'Summary',
                id:'6'
            },
        ],        
    }),
    computed: {
        activeNavItems() {
            let pages = this.$store.state.pages.filter(page => {
                if(page.parent === undefined || page.parent === '0') {
                    return true;
                }
            });
            let mainPages = [];
            mainPages.push(this.navItems[0]);
            pages.forEach(element => {
                mainPages.push(this.navItems[this.navItems.findIndex(page => page.id === element.id)])                
            });
            return mainPages;          
        }
    },
    mounted: function() {            
        this.checkForMobileNav();
        window.addEventListener("resize", this.checkForMobileNav);
    },
    updated: function() {
        this.checkForMobileNav();
        window.addEventListener("resize", this.checkForMobileNav);
    },    
    beforeDestroy: function () {
        window.removeEventListener("resize", this.checkForMobileNav);
    },
    methods : {
        checkForMobileNav: function() {           
            if(window && window.innerWidth > this.widthToRevertAt) {
                this.mobileNavNeeded = false;
                this.widthToRevertAt = undefined
            }
            if(this.$refs.desktopNav) {
                if(this.$refs.desktopNav.scrollHeight >= 40) {
                    this.mobileNavNeeded = true;
                    this.widthToRevertAt = window.innerWidth;
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .mobile-menu-toggle {
        width:50px;
        height:50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        justify-self: flex-end;
        &:after {
            display:inline-block;
            width:15px;
            height:6px;
            border-top:1px solid #E43141;
            border-bottom:1px solid #E43141;
            cursor: pointer;
            content: " ";
        }    
    }
    #nav .mobile-menu {        
        position:fixed;
        top:0;
        left:0;
        height:100vh;
        width:100%;           
        box-sizing: border-box;    
        pointer-events: none;    
        background:rgba(0,0,0,0.0);
        transition:background 0.4s 0.2s cubic-bezier(0.075, 0.82, 0.165, 1); 
        overflow: hidden;
        &:after {
            width:100%;
            height:100%;
            flex-grow:1;
            flex-shrink: 0;            
            content:" ";            
        }
        > div {
            height:100%;            
            box-sizing: border-box;    
            flex-grow: 0;
            flex-shrink: 0;
            background:#202020;                    
            transform:translateX(-100%);
            transition:transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);          
            display:flex;
            flex-direction: column;
        }
        .menu-top {
            padding:20px;
            display:flex;
            justify-content: space-between;
            svg, img {
                vertical-align: middle;
            }
        }
        .nav-items {
            margin-right: 0;
            height: auto;
            overflow: auto;
            flex-grow:1;
            box-sizing: border-box;
            padding-right:20px;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-track {
                background-color: #202020;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #E43141;
            }
        }
        .nav-section {
            display: block;            
            height:auto;
            margin-bottom: 9px;
            margin-right: 0;
            text-align: left;
            a {
                text-align: left;       
                        
            }           
        }
        &.open {
            transition:background 0.4s cubic-bezier(0.075, 0.82, 0.165, 1); 
            background:rgba(0,0,0,0.8);         
            pointer-events: all;            
            > div {
                transition:transform 0.4s 0.2s cubic-bezier(0.075, 0.82, 0.165, 1); 
                transform:translateX(0);
            }
        }
    }
  .nav-items {
      flex-grow: 1;
      margin-left:20px;
  }
  #nav {
    position: sticky;
    top:0;
    left:0;
    background: rgba(32, 32, 32, 0.75);
    z-index: 4;
    padding-bottom:0.625rem;       
    padding-top:2.812em;
    text-align: right;    
    height:110px;
    box-sizing: border-box;
    backdrop-filter: blur(20px);
    > div {
        display: flex;
        align-items: flex-end;
        &.has-mobile-nav {
            justify-content: space-between;
            align-items: center;
        }
    }
    a {
        text-decoration: none;
        color:#fff;
        transition: color 0.25s cubic-bezier(1, 0.5, 0.8, 1);
        user-select: none;
        display: inline-block;
        &.router-link-active:not(.first), &.router-link-exact-active {
            color:#E43141 !important;
        }
        &:not(:last-child) {
            margin-right:0.95em;
        }
    }
    @media (min-width:1200px) {
      a {
          &:not(:last-child) {
            margin-right:2em;
        }
      }
    }
  }
</style>
<template>
  <transition name="modal-fade">
    <section @click="close">
      <div>
        <button @click="close">
          <img src="@/assets/icons/close.svg" alt="" />
        </button>
        <slot name="content"></slot>
      </div>
    </section>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-fade-enter-active {
  transition: all 0.25s 0s ease;
  opacity: 0;  
  > div {
    transform: scale(1.1);
    opacity: 0;
  }
  button {
    transition: all 0s;
    opacity: 0;
  }
}
.modal-fade-leave-active {
  transition: all 0.5s 0.05s ease;
  opacity: 0;
  > div {
    transform: scale(1.1);
    opacity: 0;
  }
  button {
    transition: all 0s;
    opacity: 0;
  }
}
button {
  position: absolute;
  right: 1em;
  top: 20px;
  width: 45px;
  height: 45px;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.5s 0.5s;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  img {
    width: 100%;
    height: 100%;
    transform: rotateZ(45deg);
  }
}
section {
  transition: all 0.5s 0s ease;
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  overscroll-behavior: contain;
  z-index: 9999;
  scroll-behavior: smooth;
  box-sizing: border-box;
  text-align: center;
  background-image:url('~@/assets/images/sign-up.jpg');
  background-size: cover;
  background-position: center;
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  > div {
    transition: all 0.5s 0s ease;
    opacity: 1;
    width: 100%;
    height: 90%;
    max-width: unset;
  }
}
</style>
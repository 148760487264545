import { createStore } from 'vuex'

export default createStore({
  state: {
    sessionDbId:'',
    purl:'',
    baseUrl:'https://enmarketarena.vipfanportal.com/api/',
    client:{},    
    rep:{},    
    pages:[],
    summary:{},
    preview:false,
    isScrolling:false,
    blockScroll: false,
    showSignup: false,
    linkScrolling:false
  },
  mutations: {   
    setSessionDBid (state, newValue) {
        state.sessionDbId = newValue
    },
    setPreview (state, newValue) {
      state.preview = newValue
    },
    setPurl (state, newValue) {
      state.purl = newValue
    },
    setSummary (state, newValue) {
      state.summary = newValue
    },
    setPages (state, newValue) {
        state.pages = newValue
    },
    setClient (state, newValue) {
      state.client = newValue
    },
    setRep (state, newValue) {
      state.rep = newValue
    },
  },
  actions: {
  },
  modules: {
  }
})

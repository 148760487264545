<template>
  <a v-if="link" download target="_blank" :href="`https://enmarketarena.vipfanportal.com/upload/${path}`" class='button' :class="[secondary ? 'secondary' : '']"><span>Download {{text}}</span></a>
  <button v-else class='button' :class="[secondary ? 'secondary' : '']"><span>{{text}}</span><Arrow /></button>
</template>

<script>
import Arrow from '@/svg/arrow';
export default {
    name:'Button',
    components: {
        Arrow
    },
    props: {
        text:String,
        secondary:Boolean,
        link:Boolean,
        path:String
    }
}
</script>

<style lang="scss" scoped>
    .button {
        text-decoration: none;
        background:none;
        border:none;
        text-transform: uppercase;
        padding:0;
        margin:0; 
        display: inline-block;        
        box-sizing: border-box;
        padding: 10px 20px;
        padding-right:30px;
        color:#fff;
        background: #E43141;
        border:1px solid #E43141;
        cursor: pointer;
        user-select: none;
        svg {
            margin-left:10px;
            stroke:#FFF;
        }
        &.secondary {
            background:transparent;
            color:#E43141;
            svg {
                stroke:#E43141;
            }
        }
        &:not(:last-child) {
            margin-right:10px;
        }        
        > * {
            vertical-align: middle;
        }
    }
</style>
<template>
    <div :class="[subtitle ? 'has-subtitle': '']">
        <h4 class='dark'  v-if="subtitle" v-html="subtitle"/>
        <h1 :class="[dark ? 'dark' : 'white']" v-html="title"/>
  </div>
</template>

<script>
export default {
    name:'PageTitle',
    props : {
        title:String,
        dark:Boolean,
        subtitle: String
    }
}
</script>

<style lang="scss" scoped>    
    h1 {       
        padding-top:75px;
        text-align: left;
        position: relative;
        &:after {
            background:#E43141;
            height:7px;
            width:65px;
            display: block;
            border-radius: 7px;
            margin-top:10px;
            margin-bottom:15px;
            content:"";
        }
    }
    .has-subtitle h1 {
        padding-top:0;
    }
    h4 {
        position: relative;     
        padding-top:75px;
    }
</style>
<template>
  <div class='add-padding' @click.stop>
    <div class="sign-up" v-if="showForm">
        <PageTitle title="Sign up for more<br/>info & updates"/>
        <article class="form-element" :class="fullName !== '' ? 'has-value' : 'has-error'">
            <label class='body caps white' for="fullName">*Full Name</label>
            <div>
                <input type="text" name="fullName" id="fullName" v-model="fullName" placeholder="Firstname Lastname"/>
            </div>
        </article>
        <article class="form-element" :class="companyName !== '' ? 'has-value' : ''">
            <label class='body caps white' for="companyName">Company Name (if applicable)</label>
            <div>
                <input type="text" name="companyName" id="companyName" v-model="companyName" placeholder="Company Name"/>
            </div>
        </article>
        <article class="form-element" :class="[
              phoneNumber !== '' ? 'has-value' : 'has-error',
              phoneNumber !== '' && phoneNumber.replace(/\D/g, '').length < 10
                ? 'has-error'
                : '',
            ]">
            <label class='body caps white' for="phoneNumber">*Phone Number</label>
            <div>
                <input type="text" name="phoneNumber" id="phoneNumber" v-model="phoneNumber" placeholder="Phone number"/>
            </div>
        </article>
        <article class="form-element" :class="[
              email !== '' ? 'has-value' : '',
              email !== '' && testEmail.test(email) ? '' : 'has-error',
            ]">
            <label class='body caps white' for="email">*Email</label>
            <div>
                <input type="text" name="email" id="email" v-model="email" placeholder="Your email goes here..."/>
            </div>
        </article>
        <article class="last form-element" :class="[
            zipCode !== '' ? 'has-value' : 'has-error',
            zipCode !== '' && zipCode.replace(/\D/g, '').length < 5 ? 'has-error' : '',
            zipCode !== '' && zipCode.replace(/\D/g, '').length > 5 && zipCode.length !== 9
              ? 'has-error'
              : '',
          ]">
            <label class='body caps white' for="zipCode">*Zip Code</label>
            <div>
                <input type="text" name="zipCode" id="zipCode" v-model="zipCode" placeholder="Zip Code"/>
            </div>
        </article>
        <Button text='Sign Up' @click="submitForm"/>
        <Social />
    </div>
    <div class="sign-up"  v-else>
        <PageTitle title="Thanks for Signing Up"/>
        <div class="message body white">
            We will reach out with new information and updates soon.
        </div>
        <Button text='Close' @click="$store.state.showSignup = $store.state.blockScroll = false;"/>
        <Social />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import PageTitle from '@/components/PageTitle';
import Button from '@/components/Button';
import Social from '@/components/Social';
export default {
    name:'SignUp',
    components: {    
        Button,    
        PageTitle,
        Social
    },    
    methods : {
        onSubmitSuccess(response) {
            if (response.data.status == "ok") {
                this.showForm = false;
            } else {
                alert("There was a problem with your submission, please try again later");
                this.submitting = false;
            }
        },
        onSubmitError() {
            alert("There was a problem with your submission, please try again later");
            this.submitting = false;
        },
        submitForm() {
            //check no errors,
            //submit;
            
            let errors = this.$el.querySelectorAll(".form-element.has-error").length;
            if (errors > 0) {
                return;
            }
            if(this.submitting) {
                return;
            }
            this.submitting = true;

            let post = {
                fullName: this.fullName,
                companyName: this.companyName,                
                phoneNumber: this.phoneNumber,                
                email: this.email,                
                zipCode: this.zipCode,    
                purl:this.$store.state.purl
            }; 
            axios.post(this.$store.state.baseUrl + "SubmitForm.php", post)
                .then(this.onSubmitSuccess)
                .catch(this.onSubmitError)
        }           
    },
    data () {
        return {
            fullName:"",
            companyName:"",
            phoneNumber:"",
            email:"",
            zipCode:"",

            testEmail: /^.+@.+\..+$/,
            showForm: true,
            submitting: false,
        }
    },
}
</script>

<style lang="scss" scoped>    
    .sign-up {
        max-width: 420px;
        margin-left:auto;
    }
    .message {
        padding:1rem 0;
    }
    .form-element {
        margin-bottom: 20px;
        &.last {
            margin-bottom: 35px;
        }
        > div {
            background: rgba(32, 32, 32, 0.75);
            border:1px solid transparent;
            transition: border 0.5s ease;
        }
        input {
            background:none;
            border:none;
            color: rgba(255, 255, 255, 0.5);
            padding:15px 10px;
            text-align: center;
            width:100%;
            box-sizing: border-box;
            outline:none;
        }
        &.has-error {
            > div {
                border:1px solid #E43141;
            }
        }
    }
    @media (min-width:475px) {
        .form-element {
            margin-bottom: 10px;
            input {
                text-align: left;
            }
        }
        .sign-up {
            text-align: left;
        }
    }
</style>
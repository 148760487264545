<template>
    <div class='social'>
        <a target="_blank" href="https://www.instagram.com/theenmarketarena/"><img src="@/assets/icons/instagram.svg" alt=""></a>
        <a target="_blank" href="https://www.facebook.com/theenmarketarena"><img src="@/assets/icons/facebook.svg" alt=""></a>
        <a target="_blank" href="https://twitter.com/enmarket_arena"><img src="@/assets/icons/twitter.svg" alt=""></a>                
    </div>
</template>

<script>
export default {
    name:'Social'
}
</script>
<style lang="scss" scoped>
.social {
    margin-top:30px;
    a {
        &:not(:last-child) {
            margin-right:45px;
        }
    }   
}
</style>